<template>
  <b-row class="match-height">
    <b-card>
      <h2>Valeur 1 USD <span class="text-success"> </span></h2>
      <hr />
      <h3>Ville: {{ sku_town }}</h3>
      <h3>CDF: {{ cdf_amount }}</h3>
    </b-card>
    <b-card style="margin-left: 10px">
      <h2>Historique:</h2>
      <hr />
      <h4>------------</h4>
      <h4>------------</h4>
      <h4>------------</h4>
      <h4>------------</h4>
    </b-card>
  </b-row>
</template>

<script>
import { BRow, BCol, BCard } from "bootstrap-vue";
import { $themeConfig } from "@themeConfig";
import axios from "axios";

export default {
  components: {
    BRow,
    BCol,
    BCard,
  },
  data() {
    return {
      sku_corporation: "1",
      sku_town: "-",
      cdf_amount: "-",
    };
  },
  mounted() {
    this.getExchangeInfos();
  },
  methods: {
    async getExchangeInfos() {
      const final_response = await axios
        .get(
          $themeConfig.app.api_endpoint +
            `get_exchange_infos/${$themeConfig.app.api_endpoint_access_token}/${this.sku_corporation}`
        )
        .then((response) => {
          var exchange_data = response.data.exchangeInfos;
          if (exchange_data != null) {
            this.sku_town = exchange_data.sku_town;
            this.cdf_amount = exchange_data.cdf_amount;
          } else {
          }
        })
        .catch((error) => {
          console.log(error);
        });
      return final_response;
    },
  },
};
</script>
